/* Updated Carousel Styling */
.carousel-header-container {
    position: relative;
    height: 80vh;
    margin-top: 100px;
}

.carousel-overlay-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    color: white;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 8px;
}

.carousel-service-name {
    font-size: 3em;
    font-weight: bold;
    margin: 0;
}

.carousel-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.carousel-section {
    margin-top: 70px;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .carousel-header-container {
        height: 60vh;
    }

    .carousel-overlay-content {
        display: none;
    }

    .carousel-service-name {
        font-size: 2.5em;
    }
}

@media (max-width: 768px) {
    .carousel-header-container {
        height: 50vh;
    }

    .carousel-service-name {
        font-size: 1.5em;
    }
}

@media (max-width: 480px) {
    .carousel-header-container {
        height: 35vh;
    }

    .carousel-service-name {
        font-size: 1em;
    }
}
