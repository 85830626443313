.services {
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  padding-bottom: 6rem;
}

.services-background-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
    z-index: -1;
}

.border-line-div-services{
  margin-left: auto;
  margin-right: auto;
  width: 15%;
  margin-bottom: 2rem;

}
.border-line-services{
  display: flex;
  border-top: 2px solid darkgrey;
}

.service-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.service {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border-radius: 10px;
  background: transparent;
  /* width: 25%; */
  box-shadow: 0.2px 1px 1px 1.5px black;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.carousel.carousel-slider{
  border-start-start-radius: 10px;
  border-start-end-radius: 10px;
}
.service:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.service-heading {
  padding-bottom: 20px;
  font-size: small;
}

.details-cont {
  font-size: medium;
  padding: 20px;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-weight: 550;
  z-index: 1;
  font-size: 2rem;
  color: black;
  background: none;
  border: none;
  border-radius: 50%;
  padding: 0.5rem;
  user-select: none;
  outline: none;
}

.custom-arrow-prev {
  left: 10px;
}

.custom-arrow-next {
  right: 10px;
}

.custom-arrow:hover {
  color: #333;
  background: rgba(255, 255, 255, 0.7);
}

.video {
  width: 100% !important;
}

.video Video {
  height: 250px !important;
  object-fit: fill;
}

.lrn-more-btn {
  align-items: center;
  background-color: initial;
  background-image: linear-gradient(#464d55, #25292e);
  border-radius: 8px;
  border-width: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .1), 0 3px 6px rgba(0, 0, 0, .05);
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  font-family: expo-brand-demi, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 18px;
  height: 52px;
  justify-content: center;
  line-height: 1;
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: 0 32px;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 150ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.carousel .carousel-status {
  color: black !important;
}

.button-84:hover {
  box-shadow: rgba(0, 1, 0, .2) 0 2px 8px;
  opacity: .85;
}

.button-84:active {
  outline: 0;
}

.button-84:focus {
  box-shadow: rgba(0, 0, 0, .5) 0 0 0 3px;
}

@media (max-width: 550px) {
  .details-cont{
    padding: 2px;
  }
  .service-grid {
    position: relative;
    width: 90% !important;
    left: 5% !important;
    gap: 15px !important;
  }
  .service-heading {
    padding-bottom: 0 !important;
    font-size: 20px;
  }
  .video Video {
    height: 400px !important;
    object-fit: fill;
  }
  .service {
    padding: 10px !important;
    width: 100% !important;
    /* height: 500px !important; */
  }
  .lrn-more-btn {
    font-size: 20px !important;
    height: 60px !important;
  }
  .service-img {
    height: 300px !important;
  }
  .video {
    height: 300px !important;
  }
  .custom-arrow {
    display: none;
  }
}
@media (min-width: 300px){
  .service-grid{
    width: 90% !important;
    left: 5% !important;
    position: relative;
  }
  .service{
    width: 100% !important;
  }
}

@media (min-width: 550px){
  .lrn-more-btn {
    font-size: 12px !important;
    height: 40px !important;
  }
  .service-grid{
    width: 90% !important;
    left: 5% !important;
    position: relative;
  }
  .service{
    width: 47% !important;
  }
}

@media (min-width: 900px){
  .service{
    width: 23% !important;
  }
}