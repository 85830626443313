.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.app-container {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  position: relative;
  overflow: hidden;
}

.h1-title{
  margin: 0;
  padding-top: 5rem;
  padding-bottom: 2rem;
  font-weight: bolder;
  font-size: 2.5rem;
  font-family: "Oswald", sans-serif;
}
.h2-title{
  font-weight: 600;
  font-size: 1.6rem;
  font-family: "Oswald", sans-serif;
}

.content {
  /* margin-top: 10rem; */
  /* padding-top: 10px; */
  /* position: relative; */
  z-index: 1; /* Ensure content is below the carousel */
  background-color: white;
}
/* section {
  /* margin: 10px 0; */
/* }  */
.header-content{
  width: 100%;
  padding-top: 15px;
  display: flex;
  align-items: center;
  position: fixed;
  transition: padding 0.5s ease;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.2); /* Add shadow only at the bottom */

}
.pageTitle{
  position: relative;
  z-index: 1; 
  color: white;
  height: 100vh; 
}

.logoTitle{
  margin-left: 1.5rem;
  position: relative;
  /* top: 15px; */
  padding-bottom: 10px;
  text-align: center;
}

.Logo{
  height: 5.5pc;
  align-items: center;
}

/* Footer section */
.footer-container{
  background-color: rgba(0, 0, 0, 0.2);
}
.footer-logo{
  padding-top: 2rem;
}
.copyright-container{
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: black;
  font-weight: 600;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
}
.copyright-para{
  top: 0%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .header-content{
    display: flex;
  }
  .content{
    margin-top: 0rem;
  }
  .h1-title{
    padding: 2rem 0;
  }
}

@media (max-width: 550px){
  .pageTitle{
    height: 80vh;
  }
  .logoTitle {
    padding-bottom: 5px;
    
  }
  .header-logo{
    float: left;
  }
  .h2-title{
    font-size: 20px;
  }
  .nav{
    display: grid !important;
  }
  .nav{
    padding: 20px 0 20px 0 !important;
  }
  .nav a{
    margin: 20px 0;
  }
  .footer-nav .nav-links{
    flex-direction: column;
  }
  .copyright-container{
    font-size: 12px !important;
    position: relative;
    bottom: 0;
    width: 100%;
  }
}

@media (max-width: 619px){
  .nav{
    display: grid !important;
  }
  .nav{
    padding: 20px 0 20px 0 !important;
  }
  .nav a{
    margin: 20px 0;
  }
}
