
  .nav {
    padding: 15px 0 15px 0;
    display: flex;
    justify-content: center;
    font-size: larger;
    font-weight: bold;
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
    margin-right: 2rem; 

  }
  .footer-nav{
    float: none;
  }
  .footer-nav .nav-links {
    display: flex;
    flex-direction: row;
    width: auto;
    background-color: transparent;
    position: static;
    padding: 0;
  }
  
  .footer-nav .nav-links li {
    margin: 0.5rem 0;
  }
  
  .footer-nav .nav-icon {
    display: none;
  }
  .nav a {
    margin-left: 20px;
    text-decoration: none;
    font-weight: 800;
    color: black;
    cursor: pointer;
  }
  
  .nav a:hover {
    text-decoration: underline;
  }
  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
  }
  
  .nav-icon {
    display: none;
    cursor: pointer;
    font-size: 1.5rem;
  }
  
  .nav-links {
    display: flex;
    list-style: none;
  }
  
  .nav-links li {
    margin-left: 1rem;
  }
  
  .nav-links li a {
    color: black;
    text-decoration: none;
    font-family: "Open Sans", sans-serif;
  }
  .nav-links li button {
    background: none;  /* Remove default button background */
    border: none;      /* Remove default button border */
    cursor: pointer;   /* Change the cursor to pointer to mimic a link */
    font-size: inherit; /* Ensure the font size matches the surrounding text */
    color: black;      /* Default font color */
    text-decoration: none; /* Remove underline by default */
    font-family: "Open Sans", sans-serif;
    font-weight: bold; /* Make font bold */
    padding: 10px 15px; /* Add consistent padding to prevent movement */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
  }

  .nav-links li button:hover {
    text-decoration: underline; /* Add underline on hover */
    background-color: black;    /* Change background to black on hover */
    color: white;               /* Change font color to white on hover */
    font-weight: bold;          /* Make sure the text remains bold */
    padding: 10px 15px;         /* Add some padding to make the background color more visible */
    border-radius: 5px;         /* Optional: Add rounded corners to the background */
  }

  .nav-links.nav-active {
    display: block;
    position: absolute;
    top: 90%;
    left: 0;
    width: 100%;
    background-color: dimgray;
    color: white;
    padding: 1rem;
  }
  @media (max-width: 1008px) {
    .nav{
        float: right;
        /* margin-top: 1.5rem !important; */
        /* margin-right: 0.5rem; */
    }
    .footer-nav{
        padding-top: 0 !important;
        margin-top: 0 !important;
        float: none !important;
    }
    .footer-nav .nav-links{
        flex-direction: column !important;
    }
    .nav-icon {
      display: block;
    }
  
    .nav-links {
      display: none;
      flex-direction: column;
      width: 100%;
      top: 83% !important;
    }
  
    .nav-links li {
      margin: 1rem 0;
      text-align: center;
    }
    .nav-links li button{
        color: #fff;
        font-size: 1.7rem;
    }
    .nav-container{
        background-color: none;
    }
  }
  