.selected-images {
  /* margin-top: 15rem; */
  text-align: center;
  padding-bottom: 30px;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  /* background-color: silver; */
  }
/* dialog button{
  display: none !important;
}   */
.image-grid {
  gap: 50px;
  width: 90%;
  margin-left: 5%;
}

.image-gallery .gallery-image {
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

 .gallery-image.big {
  width: 100%; /* Full width */
  height: 150px; /* Height for big images */
}
 .gallery-image.small {
  width: 100%; /* Full width */
  height: 100px; /* Height for small images */
}
 .gallery-image:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.thumbnail {
width: 40%;
height: auto;
cursor: pointer;
border-radius: 10px;
overflow: hidden;
}

.thumbnail img {
/* width: 100%; */
height: 60%;
border-radius: 10px;
}

.modal-image img {
max-width: 80vw;
max-height: 80vh;
width: auto;
height: auto;
margin: auto;
display: block;
}

@media (max-width: 550px) {
  /* .selected-images{
    margin-top: 6rem !important;
  } */
  .image-grid{
    display: grid !important;
  }
  .selected-images{
    padding-bottom: 15px !important;
  }
  .thumbnail{
    width: 100%!important;
  }
}

@media (min-width: 1800px){
  .image-grid div{
    columns: 600px !important;
    column-gap: 20px !important;
  }
}