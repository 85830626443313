.background-carousel {
    position: absolute;
    top: 0%;
    width: 100%;
    z-index: -1;
    overflow: hidden;
}
.carousel{
    position: sticky !important;
}
.carouselImgs {
    object-fit: cover;
}

.carousel-slide {
    position: relative;
    width: 100%;
    height: 100vh;
    /* Adjust height as needed */
}

.carousel-background {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: absolute;
    opacity: 1;
    top: 0;
    left: 0;
    z-index: 0;
}

.carousel-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    /* Make sure overlay is on top of the background */
}

.text-over-image {
    text-align: center;
    transform: unset !important;
    position: absolute;
    top: 47%;
    left: 0;
    width: 100%;
    background-image: linear-gradient(var(--hero-light-gradient));
    animation: fade-left 0.75s ease forwards;
    font-family: "Platypi", serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
    text-transform: uppercase;
}

.title-over-image {
    font-size: 2.9rem;
    /* padding: 0 4rem; */
    position: relative;
}

.para-over-image {
    padding: 0 4rem;
    position: relative;
    font-size: 1.8rem;
}

/* .btn-over-image {
    align-items: center;
    background-image: linear-gradient(#464d55, #25292e);
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1), 0 3px 6px rgba(0, 0, 0, .05);
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: expo-brand-demi, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 18px;
    height: 52px;
    line-height: 1;
    padding: 0 32px;
    text-transform: uppercase;
    font-family: "Platypi", serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
} */

.btn-over-image {
    align-items: center;
    background: none; /* No background */
    border: 2px solid #fff; /* White border for the outline */
    border-radius: 8px; /* Rounded corners */
    box-shadow: none; /* No shadow for a flat look */
    color: #fff; /* Text color */
    cursor: pointer;
    font-family: "Platypi", serif; /* Font style */
    font-size: 18px;
    height: 52px;
    line-height: 1;
    padding: 0 32px;
    text-transform: uppercase;
    font-weight: 800;
    font-style: normal;
    outline: none; /* Remove the outline on focus */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

.btn-over-image:hover {
    background-color: rgba(255, 255, 255, 0.584); /* Light background on hover */
    color: #1b1b1b; /* Text color on hover */
}


.carousel-content {
    position: relative;
    z-index: 2;
    color: white;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}

.carousel-content h2 {
    font-size: 2rem;
    /* Adjust font size as needed */
    background-color: rgba(0, 0, 0, 0.5);
    /* Optional: Add background color to content for better readability */
    display: inline-block;
    padding: 10px 20px;
    border-radius: 10px;
}

@media (max-width: 650px) {
    .text-over-image{
        top: 40% !important;
    }
    .title-over-image{
        font-size: 1.7rem !important;
    }
    .para-over-image{
        padding: 0 !important;
    }
    .btn-over-image{
        font-size: 14px;
    }
}
@media (max-width: 768px) {
    .carousel-background {
        height: 80vh !important;
    }

    .carousel-slide {
        height: 80vh !important;
    }
}
@media (max-height: 640px) {
    .title-over-image{
        font-size: 1.4rem !important;
    }
}
@media (max-height: 1600px){
    /* .text-over-image{
        top: 15%;
        
    } */
    /* .title-over-image{
        font-size: 1.9rem !important;
    } */
    .para-over-image{
        font-size: 1.8rem !important;
    }

}